import { ShoppingBasket } from "lucide-react";

import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import type { Category, User } from "@/types";
import { useEffect, useRef, useState } from "react";
import { FiHelpCircle, FiSearch, FiShoppingCart, FiUser } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import LogoutButton from "./logout-button";
import { Input } from "./ui/input";

interface Props {
  user: User | null;
  logo: string;
  name: string;
  showSearchBar?: boolean;
  categories: Category[];
}

const Navbar = ({
  user,
  logo,
  name,
  showSearchBar = true,
  categories,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  // TODO:// update card count
  const [cartCount, setCartCount] = useState(2);
  // TODO: move is hovered to store
  const [isHovered, setIsHovered] = useState(false);

  const [isDropDownHovered, setIsDropDownHovered] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isMobileSearchVisible, setIsMobileSearchVisible] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleMobileSearch = () =>
    setIsMobileSearchVisible(!isMobileSearchVisible);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header
      className="my-6 py-4 shadow bg-gradient-to-br from-[#EBC9AB] to-[#eccaac]"
      id="main-header">
      <div className="container relative">
        {/* desktop */}
        <div
          className={cn(
            "lg:block transition-all ease-out duration-300 space-y-2"
          )}>
          <nav
            className={cn(
              "bg-secondary fixed top-0 left-0 right-0 z-50 shadow-md p-2"
            )}>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                {/* Logo */}
                <div className="flex-shrink-0">
                  <a href="/">
                    <img
                      src={logo}
                      alt="logo"
                      className="h-8 w-auto cursor-pointer"
                    />
                  </a>
                </div>

                {/* Search & Dropdown */}
                <div className="flex-grow hidden lg:flex items-center justify-center mx-4">
                  <div className="relative flex items-center bg-white rounded-md w-full max-w-3xl">
                    <div
                      className="relative group hidden"
                      ref={dropdownRef}
                      onMouseEnter={() => setIsDropDownHovered(true)}
                      onMouseLeave={() => setIsDropDownHovered(false)}>
                      <button
                        onClick={toggleDropdown}
                        className="flex items-center text-black hover:text-primary transition-colors px-4 py-2 rounded-l-md group-hover:bg-gray-100 lg:hover:bg-transparent lg:group-hover:bg-transparent">
                        Products
                        <IoMdArrowDropdown className="ml-1" />
                      </button>
                      {/* Dropdown Menu */}
                      {(isOpen || isDropDownHovered) && (
                        <div className="absolute z-10 mt-2 w-48 rounded-md bg-white shadow-lg">
                          <div className="py-1 max-h-[50vh] overflow-y-auto">
                            {categories.map((category) => (
                              <a
                                key={category.id}
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-accent">
                                {category.name}
                              </a>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-px h-6 bg-gray-300 mx-2"></div>
                    {/* search */}
                    {showSearchBar && (
                      <SearchComponent
                        isHovered={isHovered}
                        setIsHovered={setIsHovered}
                      />
                    )}
                  </div>
                </div>

                {/* Right Side Icons */}
                <div className="hidden lg:flex items-center space-x-4 flex-shrink-0">
                  <a
                    href="/contact"
                    className="flex items-center text-black hover:text-primary transition-colors">
                    <span className="flex items-center">
                      <FiHelpCircle className="mr-1" />
                      Help
                    </span>
                  </a>

                  {user ? (
                    <>
                      <a href="/dashboard" data-astro-reload>
                        <span className="flex items-center justify-center gap-1">
                          <ShoppingBasket width="18" height="18" />
                          My Purchases
                        </span>
                      </a>

                      <a href="/dashboard" data-astro-reload>
                        <Button>Dashboard</Button>
                      </a>

                      <LogoutButton />
                    </>
                  ) : (
                    <>
                      <a href="/login" data-astro-prefetch data-astro-reload>
                        <Button variant={"outline"}>Sign In/Up</Button>
                      </a>
                      <a
                        href="/register-shop"
                        data-astro-prefetch
                        data-astro-reload>
                        <Button>Register Your Shop</Button>
                      </a>
                    </>
                  )}

                  <div className="relative">
                    <a
                      href="/cart"
                      className="text-black hover:text-primary transition-colors">
                      <FiShoppingCart className="h-6 w-6" />
                    </a>
                    {cartCount > 0 && (
                      <span className="absolute -top-2 -right-2 bg-black text-white text-xs font-bold rounded-full h-4 w-4 flex items-center justify-center">
                        {cartCount}
                      </span>
                    )}
                  </div>
                </div>

                {/* Mobile Icons */}
                <div className="lg:hidden flex items-center space-x-4">
                  <button
                    onClick={toggleMobileSearch}
                    className="text-black hover:text-primary transition-colors"
                    title="Search">
                    <FiSearch className="h-6 w-6" />
                  </button>
                  <a
                    href="/contact"
                    className="text-black hover:text-primary transition-colors"
                    title="Help">
                    <FiHelpCircle className="h-6 w-6" />
                  </a>
                  {user ? (
                    <>
                      {cartCount > 0 && (
                        <span className="absolute -top-2 -right-2 bg-secondary text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                          {cartCount}
                        </span>
                      )}

                      <LogoutButton />
                    </>
                  ) : (
                    <>
                      <a href="/login" data-astro-prefetch data-astro-reload>
                        <button
                          className="text-black hover:text-primary transition-colors"
                          title="Sign in">
                          <FiUser className="h-6 w-6" />
                        </button>
                      </a>
                    </>
                  )}
                  <div className="relative">
                    <a
                      href="/cart"
                      className="text-black hover:text-primary transition-colors"
                      title="Cart">
                      <FiShoppingCart className="h-6 w-6" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Mobile Search Bar */}
            {isMobileSearchVisible && (
              <div className="lg:hidden absolute left-0 right-0 top-16 bg-white p-4 shadow-md">
                <div className="relative">
                  <SearchComponent
                    isHovered={isHovered}
                    setIsHovered={setIsHovered}
                  />
                </div>
              </div>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

const SearchComponent = ({
  setIsHovered,
  isHovered,
}: {
  isHovered: boolean;
  setIsHovered: Function;
}) => {
  const [search, setSearch] = useState("");

  const performSearch = (
    e: React.FormEvent<HTMLFormElement> | React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    // const currentUrl = window.location.pathname;
    // const isDashboardRoute =
    //   currentUrl.startsWith("/dashboard") ||
    //   currentUrl.startsWith("/shop-dashboard");

    // if (isDashboardRoute) {
    //   window.location.href = "/products?search=" + search;
    // } else {
    //   if (search?.length < 1) {
    //     $dispatch("clear");
    //   } else {
    //     Livewire.navigate("/products?search=" + search);
    //   }
    // }
  };

  return (
    <div
      className={cn(
        "lg:max-w-4xl mx-auto flex-grow focus:outline-none focus:ring-2 focus:ring-primary rounded-md",
        { "p-1": isHovered }
      )}>
      <form
        onSubmit={performSearch}
        className="w-full flex items-center gap-x-4 pt-0 transition-all ease-out duration-300">
        <div className="relative flex-1 px-3">
          <Input
            className="flex-grow px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary rounded-md border-none shadow-none"
            placeholder="What are you looking for?"
            name="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              performSearch(e);
            }}
            onClick={() => setIsHovered(true)}
            onFocus={() => setIsHovered(true)}
            onBlur={() => setIsHovered(false)}
          />
        </div>
        <button
          type="submit"
          title="search"
          className="absolute right-0 top-0 bottom-0 px-4 bg-primary text-white rounded-r-md hover:bg-primary/90 transition-colors">
          <FiSearch className="h-5 w-5" />
        </button>
      </form>
    </div>
  );
};

export default Navbar;
